@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'HiraginoSans-W6';
    src: local('HiraginoSans-W6'),
      url(../assets/fonts/HiraginoSans-W6.ttc) format('ttc');
  }
  @font-face {
    font-family: 'HiraginoSans-W7';
    src: local('HiraginoSans-W7'),
      url(../assets/fonts/HiraginoSans-W7.ttc) format('ttc');
  }

  html {
    -webkit-tap-highlight-color: transparent;
    scroll-behavior: smooth;
    font-family: HiraginoSans-W6, sans-serif;
  }
}

@layer utilities {
  .break-keep-all {
    word-break: keep-all;
  }

  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  .text-reverse {
    transform: scaleX(-1);
    z-index: 1;
  }

  .overlay {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 2;
  }

  .esgCard {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 20%;
  }
  
  .esgClose {
    position: relative;
    top: 38px;
    left: 149px;
  }

  .productPage {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .productPageClose {
    position: relative;
    align-self: flex-end;
    top: 16px;
    right: 16px;
  }

  .icon {
    max-width: 55px;
    max-height: 55px;
    min-width: 55px;
    min-height: 33px;
  }
}

/* Fix issue with mui drawer */
.PrivateSwipeArea-root {
  z-index: 2 !important;
}

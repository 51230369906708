.navBar::before {
  content: '';
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 110px;
}
